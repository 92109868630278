export const ZD_INPUT = {
	LABEL: "text-gray-800",
	TEXT: "text-zd-gray-500",
	BACKGROUND: "bg-zd-lightestgray-500",
	BORDER: "border-zd-lightgray-500",
};

export const ZD_ERRORS = {
	LABEL: "text-zd-red-500",
	BACKGROUND: "bg-zd-red-500",
	TEXT: "text-zd-red-500",
	BORDER: "border-zd-red-500/50",
};
